import React from "react";
import AppcheckBase from "@src/layouts/AppcheckBase";

function AppcheckTeacherAddClass() {
  return (
    <AppcheckBase>
      <a
        id="app-trigger"
        href="https://teach.classdojo.com/#/classes/createOnSignup"
        data-app="https://teach.classdojo.com/#/classes/createOnSignup"
        data-app-ios="classdojo://t/addClass"
        data-app-android="classdojo://t/addClass"
        data-store-android="com.classdojo.android"
        data-store-ios="552602056"
        style={{ color: "#fff" }}
      >
        ClassDojo App
      </a>
    </AppcheckBase>
  );
}

export default AppcheckTeacherAddClass;
